import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Image } from "../../components/images.component"
import { colors } from "../structure/theme"
import { Link } from "./productLink.component"
import { addEventTracking } from "../thirdParties/gtm/tagManager"
import SmoothAnchor from "../navigation/smoothAnchor.component"
import { useTranslation } from "../translation/translate.component"

const ImageWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
  border: 1px solid ${colors(`grey.g600`)};
`

const TextImage = styled.div`
  margin-left: 25px;

  p {
    margin: 0;
  }
`

const ContainerItem = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    padding-bottom: 20px;
  }
`

function Intervention ({ icon, title }) {
  return (
    <ContainerItem data-testid="intervention">
      <div>
        <ImageWrapper>
          <Image file={icon} alt={title} />
        </ImageWrapper>
      </div>
      <TextImage dangerouslySetInnerHTML={{ __html: title }} />
    </ContainerItem>
  )
}

Intervention.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    file: PropTypes.object,
    alt: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}

function tagMoreExemples () {
  addEventTracking(`eventGA`, {
    eventCategory: `FicheProduit`,
    eventAction: `clic`,
    eventLabel: `Exemples`,
  })
}

export default function Interventions ({ interventions, limit = 0, link = false }) {
  if (!interventions) {
    return null
  }
  const { t } = useTranslation()

  return (
    <>
      {interventions
        .filter((inter, i) => limit === 0 || i < limit)
        .map(i => (
          <Intervention icon={i.icon} title={i.title} key={i.title} />
        ))}
      {link && (
        <SmoothAnchor Component={Link} href="#interventions" onClick={tagMoreExemples}>
          {t(`common:product.information_anchor_text`)}
        </SmoothAnchor>
      )}
    </>
  )
}

Interventions.propTypes = {
  interventions: PropTypes.array,
  link: PropTypes.bool,
  limit: PropTypes.number,
}

Intervention.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}
