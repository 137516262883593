import React from "react"
import PropTypes from "prop-types"
import { Image } from "../../components/images.component"
import styled from "styled-components"
import _noop from "lodash/noop"
import breakpoint from "styled-components-breakpoint"
import { condition, percent } from "../structure/theme"
import { useTranslation } from "../translation/translate.component"

const Wrapper = styled.div`
  margin-bottom: 15px;

  ${breakpoint(`medium`)`
    margin-bottom: 0;
    width: ${condition(`fullWidth`, percent(2 / 3), `100%`)}
  `}

  &::before,
  &::after {
    transform: rotate(2.5deg);
  }
`

export default function Schema ({ remoteImage, onClick = _noop, fullWidth }) {
  const { t } = useTranslation()

  return (
    <Wrapper data-testid="schema" onClick={onClick} fullWidth={fullWidth}>
      <Image file={remoteImage} alt={t(`common:product.section_schema_image_alt`)} />
    </Wrapper>
  )
}

Schema.propTypes = {
  onClick: PropTypes.func,
  remoteImage: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool.isRequired,
}
