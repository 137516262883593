import React from "react"
import Proptypes from "prop-types"
import Schema from "./schema.component"
import Interventions from "./interventions.component"
import { addEventTracking } from "../thirdParties/gtm/tagManager"
import _noop from "lodash/noop"
import { isWysiwygEmpty } from "../../utils/wysiwyg"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { percent } from "../structure/theme"

const InterventionContainer = styled.div`
  padding-left: 5px;
  width: 100%;

  ${breakpoint(`medium`)`
    padding-left: 30px;
    width: ${percent(1 / 3)};
  `}
`

function tagSchema () {
  addEventTracking(`eventGA`, {
    eventCategory: `FicheProduit`,
    eventAction: `clic`,
    eventLabel: `schéma`,
  })
}

const CoverDescription = styled.div`
  margin-bottom: 16px;
  text-align: center;

  > p:first-of-type {
    margin-top: 0;
  }
`

const Wrapper = styled.div`
  ${breakpoint(`medium`)`
      display: flex;
      width: 100%;
  `}
`

export default function SchemaCover ({ product: { cover, coverDescription, interventions }, hasTag = false }) {
  const hasInterventions = interventions.length > 0

  return (
    <>
      {!isWysiwygEmpty(coverDescription) && (
        <CoverDescription data-testid="schema_description" dangerouslySetInnerHTML={{ __html: coverDescription }} />
      )}
      <Wrapper>
        <Schema fullWidth={hasInterventions} remoteImage={cover} onClick={hasTag ? tagSchema : _noop} />
        {hasInterventions && (
          <InterventionContainer id="interventions">
            <Interventions interventions={interventions} />
          </InterventionContainer>
        )}
      </Wrapper>
    </>
  )
}

SchemaCover.propTypes = {
  product: Proptypes.shape({
    cover: Proptypes.object.isRequired,
    interventions: Proptypes.array.isRequired,
    coverTitle: Proptypes.string.isRequired,
    coverDescription: Proptypes.string.isRequired,
  }).isRequired,
  hasTag: Proptypes.bool,
}
