import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { colors, condition } from "../structure/theme"

const style = css`
  color: ${colors(`primary`)};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
  font-size: ${props => props.fontSize};

  &::before {
    content: ">";
    display: inline-block;
    margin-right: 5px;
    transition: transform 0.2s;
    transform: ${condition(`isOpen`, `rotateZ(90deg)`)};
  }
`

export const Link = styled.a`
  ${style}
`

const Span = styled.span`
  ${style}
`

export default function ProductLink ({ href = ``, children, ...props }) {
  if (href) {
    return (
      <Link href={href} {...props}>
        {children}
      </Link>
    )
  }

  return <Span {...props}>{children}</Span>
}

ProductLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
}
